import axios from 'axios'
import { MessageBox, Message} from 'element-ui'
import { getToken } from '@/utils/auth'
// import router from '@/utils/router'

const service = axios.create({
    baseUrl: '/',
    timeout: 5000,
})

service.interceptors.request.use(config=>{
    if(getToken()){
        config.headers['Authorization'] = ' Bearer '+getToken();
    }
    config.headers['Content-Type']='application/json;charset=UTF-8'
    return config
},error=>{
    console.log(error)
    return promise.reject(error)
})

service.interceptors.response.use(response=>{
    // if(response.status !== 200){
    //         Message({
    //             message: response.message || 'error',
    //             type:'error', 
    //             duration:5000
    //         }) 
    // }
    return response
},error=>{
    // alert('服务器数据响应失败'+error)
    console.log(error);
})

export default service