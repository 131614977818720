import request from '@/utils/request'

const state = {}

const  mutations={}

const actions ={
    async setAuthUser({commit}){
        try {
            let result =  await request.get('api/user')
            // console.log(result);
            // commit({
            //     type: types.SET_AUTH_USER,
            //     user: result.data
            // }) 
        } catch (error) {
            // dispach('refreshToken')
        }
    }
}

export default {
    namespaced:true, 
    state,
    mutations,
    actions
}