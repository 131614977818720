import Vue from 'vue'
import Router from 'vue-router'
import layout from '@/views/layout/index'
import { getToken } from "@/utils/auth"
Vue.use(Router)
import nprogress from 'nprogress'
import 'nprogress/nprogress.css'

 const constantRouter = [
    { 
        path: '/login',
        name:'login',
        component:()=>import('@/views/login/login'),
        meta:{}
    },
    { 
        path:'/',
        name:'layout',
        component:layout,
        redirect: '/home',
        meta:{},
        children:[
            {
                path:'home',
                name:'Home',
                component:()=>import('@/views/layout/component/home')
            },
            {
                path:'orders',
                component:()=>import('@/views/layout/component/orders')
            },
            {
                path:'income',
                component:()=>import('@/views/layout/component/income')
            },
            {
                path:'bills',
                component:()=>import('@/views/layout/component/bills')
            },
        ]
    }
   
]
const createRouter = () => {
    return  new Router({
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRouter,
        mode:'history'
        })
    }
const router = createRouter()
router.beforeEach((to,from,next)=>{
    nprogress.start()
    if(getToken()){
        if(to.path=='/login'){
            next('/');
        }else{
            next();
        }
    }else{
        if(to.path !=='/login'){
            next('/login');
        }else{
            next();
        }
        
    }
})
router.afterEach((to,from,next)=>{
    nprogress.done()
})
export default router