<template>
<div class="container">
  <el-container >
        <el-aside :width="asideWidth" ref="aside">
            <asidebar :isMenu="isMenu"></asidebar>
        </el-aside>
        <el-container>
            <el-header>
                <navbar></navbar>
            </el-header>
                <el-main class="main">
                    <div>
                    <el-card shadow="hover">
                        <transition name="main-fade">
                            <router-view></router-view>
                        </transition>
                    </el-card>
                    </div>
                </el-main>
            <el-footer>青岛大数据科技发展有限公司提供技术支持</el-footer>
        </el-container>
    </el-container>
</div>
</template>

<script>
import navbar from './component/bavBar.vue'
import asidebar from './component/asideBar.vue'
export default {
    name:'layout', 
    data(){
        return {
            isShow:false,
            asideWidth:'150px',
            isMenu:true, 
        }
    }, 
    components:{
        navbar,
        asidebar
    },
    // computed:{
    //     isMenu(){
    //         return this.asideWidth==='30px'? false :true
    //     }
    // },
    mounted(){
        // this.$refs.aside.addEventListener('transitionEnd',()=>{
                
        //     })
        this.isShow=true;
        this.$bus.$on('menu',(data)=>{
            if(data){
                this.asideWidth='150px'
                this.isMenu=true
            }else{
                this.asideWidth='49px'
                this.isMenu=false
            }
        })
    }
}
</script>

<style scoped lang="scss">
    .el-aside{
        background-color: rgb(220,231,246);
        transition: all 0.3s;
        overflow:hidden;
    }
    .main-fade-enter{
       transform: translate3d(50%, 0, 0);
       opacity: 0;
    }
    // .el-card{
    //     overflow:auto;
    //     overflow-x: hidden;
    //     overflow-y: visible;
    // }
    .main-fade-enter-active {
        transition: all 0.8s;
        // position: absolute;
    }
    .main-fade-enter-to{     
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
    .container{
        width: 100%;
        height: 100vh;
        // overflow: hidden;
        .el-header{
         background-color: rgb(220,231,246);
        color: #333;
        text-align: center;
        line-height: 100px;
        padding: 0;
        height:80px !important;
        // overflow: hidden;
        }
        .el-footer{
            background-color: rgb(220,231,246);
            line-height: 40px;
            color: rgb(107, 103, 103);
            height: 40px !important;
            text-align: center
        }
        .main{
            padding-top: 20px !important;
            height: 88vh;
            overflow:auto;
            background-color: rgb(220,231,246);
            div{
                margin: 10px;
                border-radius:10px
            }
        }
        .el-main {
            background-color: rgb(220,231,246);
            padding:0px;
        }
    }
    
</style>