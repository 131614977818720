<template>
  <div class="left-aside">
      <!-- <el-aside width="150px"> -->
            <div class="title"  >
                <span v-show="isMenu" >影都生态观光园</span>
                <img v-show="!isMenu" src="@/assets/logo.png" width="40" height="40" alt="生态观光园" />
            </div>
            <el-menu
                default-active="1"
                class="el-menu-vertical-demo"
                background-color="rgb(220,231,246)"
                text-color="#000"
                active-text-color="rgb(24, 165, 97)">
                <router-link to="/home">
                    <el-menu-item index="1" >
                            <i class="el-icon-s-home"></i>
                            <span >概况</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/orders">
                    <el-menu-item index="2">
                            <i class="el-icon-s-ticket"></i>
                            <span >票务</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/income">
                    <el-menu-item index="3" >
                            <i class="el-icon-data-line"></i>
                            <span >收入</span>
                    </el-menu-item>
                </router-link>

                <router-link to="/bills">
                    <el-menu-item index="4">
                            <i class="el-icon-tickets"></i>
                            <span >账单</span>
                    </el-menu-item>
                    </router-link>
            </el-menu>
        <!-- </el-aside> -->
  </div>
</template>

<script>
export default {
    props:['isMenu']
}
</script>

<style scope lang="scss">
.padding-le{padding-left:10% ;position:relative;}
.el-menu-item:hover{
    outline: 0 !important;
    background-color:rgba(140, 198, 166,0.2) !important;
    &:before{
        content: "";
        position: absolute;
        width: 4px;
        height: 100%;
        background-color: green;
        left: 0;
        top: 0;
    }
}
 .left-aside{
      background-color: rgb(220,231,246);
            .title{
                 -webkit-background-clip:text;
                color:transparent;
                color:green;
                transition: all 0.5s;
                text-align: center;
                font-size:20px;
                margin:10px 0;
                height: 80px;
                line-height: 80px;
                font-weight: bold;
                position:relative;
                img{
                    position:absolute;
                    left: 9px;
                    top: 15px;
                    z-index: 555;
                }
            }
            .el-menu{
                width: 100%;
                
            }
        }
 
    
</style>