import Vuex from "vuex";
import  Vue from "vue";
Vue.use(Vuex)

import AuthUser from './modules/auth-user'

export default new Vuex.Store({
    modules: {
        AuthUser
    },
    strict:true
})