<template>
    <div class="header"> 
        <!-- <image src="@/assets/logo.png" ></image> -->
        <div class="left-menu">
            <!-- <img src="@/assets/logo.png" width="40" height="40" alt="生态观光园" />&nbsp; -->
            <span @click="menuChange">
                <i v-show="isClickMenu" class="el-icon-s-fold"></i>
                <i v-show="!isClickMenu" class="el-icon-s-unfold"></i>
            </span>&nbsp;
            <!-- <span >青 岛 影 都 生 态 观 光 园</span> -->
        </div>
        <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">
            <div class="user">
                <el-button type="success" icon="el-icon-user-solid" circle></el-button>
            </div>
            <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/">
                <el-dropdown-item @click.native="goBack">
                首页
                </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="logout">
                <span style="display:block;">退出登录</span>
            </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        </div>
    </div>
</template>

<script>
import {logOut} from '@/api/user'
import {removeToken} from '@/utils/auth'
export default {
    data(){
        return{
            isClickMenu:true
        }
    },
    methods:{
        menuChange(){
            this.isClickMenu = !this.isClickMenu
            this.$bus.$emit('menu',this.isClickMenu)
        },
        goBack(){
            this.$router.push({name:'Home'})
        },
         logout(){
            logOut('api/logout')
            removeToken()
            this.$router.push('/login')
        }
    }
}
</script>

<style scoped lang="scss">
    .header{
        padding-right: 30px;
        // background-color: rgb(220, 231, 224);
        // background-color: rgb(95, 97, 96);
        // background-color: rgb(234,241,251);
        background-color: rgb(220,231,246);
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px !important;
        margin: 0 20px;
        border-radius:10px;
        .right-menu{
            padding-top: 10px;
        }
        .left-menu{
            height: 60px;
            text-align: center;
            font-size:25px;
            font-weight: bold;
            line-height: 77px;
            img{vertical-align: middle;margin-bottom:7px}
            .user{font-size: 40px;}
            span{
                transition: all .2s;
                -webkit-background-clip:text;
                color:transparent;
                color: green;
                &:hover i{
                    // text-shadow: 0 0 5px #8fe496,0 0 10px #8fe496,0 0 15px #8fe496;
                    color: rgb(99, 174, 152);
                }
            }
        }
    }
</style>