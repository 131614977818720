import request from "@/utils/request"
import {setUserInfo} from '@/utils/auth'
//登录接口
export function checkTicketStatus(url,formData){
    return request.post(url,formData)
}
export function loginCode(url){
    return request.get(url)
}
// 获取用户信息接口
export function getUserInfo(url){
    request({
        url:url,
        method: 'GET',
    }).then(res=>{
        // console.log(res);
        let userInfo={
            id:res.data.id,
            level:res.data.level
        }
        setUserInfo(userInfo)
    })
}
//退出登录接口
export function logOut(url){
    return request({
        url:url,
        method: 'post',
    })
}