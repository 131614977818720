
import Cookie from 'js-cookie'

export function getToken(){
     return localStorage.getItem('TokenKey')
}

export function setToken(token){
     localStorage.setItem('TokenKey', token)
}

export function removeToken(){
     localStorage.removeItem('TokenKey')
     Cookie.remove('auth_id');
     localStorage.removeItem('userInfo')
}
export function setCookie(auth_id){
    Cookie.set('auth_id',auth_id)
}
export function setUserInfo(info){
     localStorage.setItem('userInfo', JSON.stringify(info))
}
